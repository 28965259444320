import React from 'react'
import { graphql } from 'gatsby'
import "react-circular-progressbar/dist/styles.css"
import "../../../css/common/common.css"
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import { CarouselResponceData } from '@retina-packages/retina-theme-bootstrap/packages/types/ImageCarouselType'
import { VideoResp } from '@retina-packages/retina-theme-bootstrap/packages/types/VideoGraphResponseType'
import InfoGraphicProgressProvider from '@retina-packages/retina-theme-bootstrap/packages/components/InfographicDonut/ReactCircularProgressbar'
import retinaConfig from '../../../utils/retinaConfigs'
import { BrightcoveVideo, HomePageWrapper, HTMLRenderer, Layout, PageWrapper, SimpleSlider } from '@retina-packages/retina-theme-bootstrap'
import { UsHeaderContainer, FooterCalloutBlock } from '../../../css/common/style'
import { PatientHome } from './constants'
import { deriveSiteLogo, deriveSiteMenus, fullHTMLDataBuilder, infographicDataBuilder, videoDataBuilder } from '../../../utils'

/** Rendering Template for Primary block
 *
 * @param props props
 * @returns
 */
const PrimaryTemplate = (props: Props) => {

  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso

  const pgContextsPHM = props.pageContext
  const htmlStaticFilesPHM = {
    nonSVGImages: pgContextsPHM.nonSVGImages,
    svgMediaImages: pgContextsPHM.svgMediaImages,
    allMediaDocument: pgContextsPHM.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: PatientHome.mainMenu, siteFooterMenu: PatientHome.footerMenu })

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name
  const bannerTitle = ""
  const carouselTitle = PatientHome.carousel
  const footerTitle = PatientHome.footer
  const exitPopupTitle = PatientHome.exitPopup
  const hcpSwitcherTitle = PatientHome.hcpSwitcher
  const topNavTitle = PatientHome.topNav

  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
    carouselData
  }: {
    footerText: string
    exitData: exitPopDataType
    hcpSwitcher: exitPopDataType
    topNavs: string
    carouselData: CarouselResponceData
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle, })


  const videoData: VideoResp = videoDataBuilder({ blocks, title: PatientHome.video1 })
  const videoData2: VideoResp = videoDataBuilder({ blocks, title: PatientHome.video2 })
  const { per, text1, text2 } = infographicDataBuilder({ blocks, title: PatientHome.infographic })
  const footerCalloutHTML = fullHTMLDataBuilder({ blocks, title: PatientHome.footrCallout })
  const siteLogos: any = deriveSiteLogo({ blocks, title: PatientHome.siteLogo })
  return (
    <>
      {/*US homepage with global package*/}
      <HomePageWrapper className='home-page'>
        <UsHeaderContainer>
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            staticMediaFiles={htmlStaticFilesPHM}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={PatientHome.backToTopGTM}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
            hcpValidate={retinaConfig.hcpValidate}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={PatientHome.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            languageConfig={{ ...retinaConfig.langConfig, ...PatientHome.redirectLangUrl }}
            {... { ...retinaConfig.layoutAttrs.dtcAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
          >
            <PageWrapper className='pagewrapper homepage-wrapper'>
              {carouselData && (
                <SimpleSlider data={carouselData} {...PatientHome.carousalAttrs} />
              )}
              <InfoGraphicProgressProvider per={per} text1={text1} text2={text2} />

              <div className='home-video-wrapper'>
                {videoData && (
                  <BrightcoveVideo
                    data={videoData}
                    accountId={retinaConfig.accountId}
                    viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                    hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                    {...PatientHome.video1GTMs}
                  />
                )}
              </div>
              {videoData2 && (
                <BrightcoveVideo
                  data={videoData2}
                  accountId={retinaConfig.accountId}
                  viewTranscriptBtnContent={retinaConfig.viewTranscriptBtnContent}
                  hideTranscriptBtnContent={retinaConfig.hideTranscriptBtnContent}
                  {...PatientHome.video2GTMs}
                />
              )}
              <FooterCalloutBlock className='o-container padding-cards-fix home-page-footer-us'>
                <HTMLRenderer html={footerCalloutHTML} data={htmlStaticFilesPHM} tagName='div' className='footer-block footer-block-bg' />
              </FooterCalloutBlock>
            </PageWrapper>
          </Layout>
        </UsHeaderContainer>
      </HomePageWrapper>
    </>
  )
}

export default PrimaryTemplate

export const pageQuery = graphql`
  query ($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... PatientHomePageQuery
      }
    }
  }
`
