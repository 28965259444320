export const PatientHome: any = {
  mainMenu: "Main Navigational Menu Consumer GCSO - Global",
  footerMenu: "Footer Navigation Menu Consumer GCSO - Global",
  carousel: "Homepage Carousal - Global",
  footer: "Retina Global Footer Consumer - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu Consumer US - Global",
  footerCallout1: "Homepage Footer Navigation Callout 1 - Global",
  footerCallout2: "Homepage Footer Navigation Callout 2 - Global",
  video1: "Home Brightcove Video Section GCSO - Global",
  video2: "Home Brightcove Video Section 2 - Global",
  infographic: "Infographic Percentage Home Consumer GCSO  - Global",
  footrCallout: "Home Footer Callout - HTML DTC US Global",
  siteLogo: "Site logos - Global",
  carousalAttrs: {
    playBtnGTM: {
      'className': '',
      'aria-label': 'Play slideshow'
    },
    pauseBtnGTM: {
      'className': '',
      'aria-label': 'Pause slideshow'
    },
    playBtnAriaDescription: ' ',
    pauseBtnAriaDescription: ' '
  },
  video1GTMs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - The genetic testing story of Allison",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for The genetic testing story of Allison"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - The genetic testing story of Allison",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for The genetic testing story of Allison"
      }
    ]
  },
  video2GTMs: {
    viewTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Genes 101"
      }
    ],
    hideTranscriptGTMLabels: [
      {
        "data-gtm-event-label": "Hide transcript - Genes 101",
        "className": "gtm-accordion",
        "aria-label": "Hide transcript for Genes 101"
      }
    ]
  },
  redirectLangUrl: {
    "redirectUrl": {
      "en": "/",
      "es": "/es/"
    }
  },
  backToTopGTM: "Back to top - Homepage",
  footerClassName: "home-footer"
}